@import '../global.scss';

.team-detail-com{
    .team-container{
        // position: relative;
        // overflow: hidden;
        // .team-name{
        //     position: absolute;text-align: left;
        //     bottom: 15%;
        // }
 
    }
}

.player-detail-com{
    min-height: 100vh;
    height: 100%;
}