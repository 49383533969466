@import '../global.scss';
.home-com{
    min-height: 100vh;
    height: auto;
    .msg-container{
        background-color: $msgConColor;
    }
    
    .menu-con{
        background-color: $menuConBgColor;
    }

    .menu-color{
        background-color: $menuColor;
        color: $menuColor;
    }

    .each-menu-con:first-child{
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        border-radius: 0.5rem;
        a{
            border-top-left-radius: 0.4rem;
            border-bottom-left-radius: 0.4rem;
            
        }
        // &:hover{
        //     background-color: #ff0058;
        //     transition: 0.2s all linear;
        // }
        
    }
    // .each-menu{
    //     &:hover{
    //         background-color: #ff0058;
    //         transition: 0.2s all linear;
    //     }
    // }
    // .each-menu-con:not(:last-child):not(:first-child) {
    //     border-left: 1px solid $menuConBorderColor;
    // }
    // .each-menu-con:last-child{
    //     border-left: 1px solid $menuConBorderColor;
    //     border-right: 1px solid $menuConBorderColor;
    // }
}


.afl-team-name{
    // @media screen and (min-width: 220px) and (max-width:340px){
    //     width: min-content;
    // }
}

.active-season{
    border-bottom: $menuColor;
}