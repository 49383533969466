body{
  min-height: 100vh;
  height: auto;
  -ms-overflow-style: none;
}

.App {
  text-align: center;
  /* background: radial-gradient(closest-side, #16425e,#121620); */
  background: radial-gradient(closest-side, #1F232D,#1F232D);
  color: white;
  min-height: 100vh;
  height: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.tb-shadow{
  box-shadow: -1px 3px 20px 1px rgba(51, 51, 51, 0.75);
}

.loader-com{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.tb-header-hover-content,
.afl-tb-header-hover-content,
.epl-tb-header-hover-content,
.nfl-tb-header-hover-content{
  position: absolute;
  width: max-content;
  top: 0%;
  /* left: 45%; */
  animation: tool-tip-animaion 0s ease-in-out 3s forwards;
  z-index: 0;
  border-radius: 0.2rem;
  background-color: black;
  color: white;
  padding: 0.2rem 0.5rem;
  
}
.afl-tb-header-hover-content{
  top: 0%;
}

.epl-tb-header-hover-content{
  top: 3%;
}

@media screen and (max-width:768px){
  .tb-header-hover-content,
  .afl-tb-header-hover-content,
  .nfl-tb-header-hover-content,
  .epl-tb-header-hover-content{
    top: 3.5%;
    left: 35%;
    /* translate: (-50%,-50%); */
  }
  .nfl-tb-header-hover-content,
  .afl-tb-header-hover-content{
    top: 0%;
  }

  .nfl-tb-header-hover-content{
    left: 20%;
  }
}


@keyframes tool-tip-animaion {
  to {
      width:0;
      height:0;
      padding: 0rem;
      overflow:hidden;
  }
}
@-webkit-keyframes tool-tip-animaion {
  to {
      width:0;
      height:0;
      padding: 0rem;
      visibility:hidden;
  }
}

.app-screen-width{
  width: 68%;
}

.sponser-screen-width{
  width: 15%;
}

/* @media screen and (max-width: 1080px) {
  .app-screen-width{ width: 100%; margin: 1%; }
} */

.app-header-bg{
  /* background-image: linear-gradient(to right,#bd1a53, #ff0058); */
  /* background: radial-gradient(closest-side, #16425e,#121620); */
  background: linear-gradient(to right,#12212E,#192F42,#12212E);
}


.sponserUI{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0.5rem 0.3rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
}


.neds {
  /* border: 4px solid transparent;
  border-image: linear-gradient(150deg, #cd9270 0%, #d6d3d0 60%);
  border-image-slice: 1; */
  border:3px solid #ff7800;
}

.boombet {
  /* border: 4px solid transparent;
  border-image: linear-gradient(150deg, #86345f 0%, #ac9261 60%);
  border-image-slice: 1; */
  /* border: 3px solid transparent;
  border-image: linear-gradient(150deg, #fb564b 40%, #900cb8 60%);
  border-image-slice: 1; */
  border: 3px solid #fc534d;
}

.ladbrokes {
  /* border: 4px solid transparent;
  border-image: linear-gradient(150deg, #191060 0%, #631865 60%);
  border-image-slice: 1; */
  border: 3px solid #cd1c24;
}

.betr {
  /* border: 4px solid transparent;
  border-image: linear-gradient(150deg, #191060 0%, #631865 60%);
  border-image-slice: 1; */
  border:3px solid #172FD4;
}

.draftstarin {
  /* border: 4px solid transparent;
  border-image: linear-gradient(150deg, #44c87c 0%, #cc5c84 60%);
  border-image-slice: 1; */
  border: 3px solid #f10251;
}

.playup {
  /* border: 4px solid transparent;
  border-image: linear-gradient(150deg, #991c2f 0%, #cac1c4 60%);
  border-image-slice: 1; */
  border: 3px solid #00f181;
}

.unibet {
  /* border: 4px solid transparent;
  border-image: linear-gradient(150deg, #688767 0%, #d6d8d5 60%);
  border-image-slice: 1; */
  border:3px solid #147b45;
}



 
 

 
progress.rtl {
  direction: rtl;
}
progress,
progress[role] {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background-size: auto;
  height: 10px;
  width: 100%;
}
progress[value]::-webkit-progress-bar {
  background-color: rgb(243, 243, 243);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
#green[value]::-webkit-progress-value {
  background-image: -webkit-linear-gradient(-45deg, transparent 33%, rgba(51, 148, 16) 33%, rgba(51, 148, 16) 66%, transparent 66%), -webkit-linear-gradient(top, rgba(51, 148, 16), rgba(51, 148, 16)), -webkit-linear-gradient(left, #339410, #339410);
  border-radius: 5px;
}

#orange[value]::-webkit-progress-value {
  background-image: -webkit-linear-gradient(-45deg, transparent 33%, #ff7729 33%, #ff7729 66%, transparent 66%), -webkit-linear-gradient(top, #ff7729, #ff7729), -webkit-linear-gradient(left, #ff7729, #ff7729);
  border-radius: 5px;
}

#orange[value]::-moz-progress-bar {
  background-image: -webkit-linear-gradient(-45deg, transparent 33%, #ff7729 33%, #ff7729 66%, transparent 66%), -webkit-linear-gradient(top, #ff7729, #ff7729), -webkit-linear-gradient(left, #ff7729, #ff7729);
  border-radius: 5px;
}

#red[value]::-moz-progress-bar{
  background-image: -webkit-linear-gradient(-45deg, transparent 33%, rgba(232, 62, 32) 33%, rgba(232, 62, 32) 66%, transparent 66%), -webkit-linear-gradient(top, rgba(232, 62, 32), rgba(232, 62, 32)), -webkit-linear-gradient(left, #e83e20, #e83e20);
  border-radius: 5px;
}
#green[value]::-moz-progress-bar{
  background-image: -webkit-linear-gradient(-45deg, transparent 33%, rgba(51, 148, 16) 33%, rgba(51, 148, 16) 66%, transparent 66%), -webkit-linear-gradient(top, rgba(51, 148, 16), rgba(51, 148, 16)), -webkit-linear-gradient(left, #339410, #339410);
  border-radius: 5px;
}

#red[value]::-webkit-progress-value {
  background-image: -webkit-linear-gradient(-45deg, transparent 33%, rgba(232, 62, 32) 33%, rgba(232, 62, 32) 66%, transparent 66%), -webkit-linear-gradient(top, rgba(232, 62, 32), rgba(232, 62, 32)), -webkit-linear-gradient(left, #e83e20, #e83e20);
  border-radius: 5px;
}
.valuebar {
  position: relative;
}
.valuebar h3 {
  color: #fff;
  left: 1em;
  line-height: 1;
  position: absolute;
}
.fb-container{
  /* padding: 3%; */
  background-color: #2a63ad;
  
}


/* ::-webkit-scrollbar{
  display: none; 
} */


::-webkit-scrollbar:horizontal {
  width: 1px;
  background-color: black;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: #fd0356;
  border-radius: 0.5rem;
}

::-webkit-scrollbar:vertical {
  width: 10px;
  background-color: black;
}

::-webkit-scrollbar-thumb:vertical {
  background-color: #fd0356;
  border-radius: 1rem;
}

.top-menu::-webkit-scrollbar{
  display: none;
}

.nba-player-data-table ::-webkit-scrollbar {
  width: 5px;
  background-color: whitesmoke;
}

.nba-player-data-table ::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 1rem;
}

.top-tb-container::-webkit-scrollbar {
  width: 5px;height: 5px;
  background-color: whitesmoke;
}
.top-tb-container::-webkit-scrollbar-thumb {
  background-color: grey;
}


.linear-wipe {
  text-align: center;
  /* border: 2px solid white; */
  color: #ff0058;
  border-radius: 4px;
  /* background: linear-gradient(89deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
  background-size: 200% auto;
  
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  animation: shine 1s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  } */
  
}

.primary-bg-color{
  background-color: #ff0058;
}

.primary-text-color{
  color: #ff0058;
}

.active-season,.season{
  background-color: #ff0058;
}

.season{
  background-color: transparent;
}

select {
  text-transform: capitalize;
}

.modal-bg{
  background-color: #121a26;
}

.nfl-team-header::-webkit-scrollbar {
  width: 1px;
  height: 3px;
  background-color: transparent;
}

.nfl-team-header::-webkit-scrollbar-thumb {
  background-color: #414141;
  border-radius: 0.5rem;
}