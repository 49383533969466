@import '../global.scss';

table{
    tr{
        th{ 
            // background-color: $menuColor; 
        }
        td{  }
    }
}

.player-table-com{
    table{
        overflow: hidden;
        table-layout: auto;
        // //some CSS
        @media screen and (min-width: 220px) and (max-width:340px){
            //do Smth
            width: 310%;
        }
        @media screen and (min-width: 341px) and (max-width:767px) {
            //do Smth
            width: 200%;
        }
        @media screen and (min-width: 769px) {
            //do Smth
            width: 100%;
        }

    }
    
    .tb-data{
        background-color: white;
    }
    .tb-data:nth-child(2n){
        background-color: white;
    }
    .tb-data:nth-child(2n){
        background-color: whitesmoke;
    }

    .tb-hth-data{
        background-color: rgb(224, 224, 224);
    }


    .tb-th-color{
        background-color: $sortedTHColor;
    }
    ::-webkit-scrollbar {
        width: 5px;
        background-color: black;
      }
      
      ::-webkit-scrollbar-thumb {
        background-color: #fd0356;
        border-radius: 1rem;
      }
}

.player-detail-com{
    .top-tb-container{

        overflow-y: auto;

        .top-table{

             
            overflow-y: auto;

            th{ background-color: white; }

            .top-tb-data:nth-child(2n+1){
                // background-color: #1a4f70;
                background-color: whitesmoke;
                padding: 0.2rem;
            }

            td{ width: 8rem;padding: 0 0.1rem;}

            .tool-tip-con{
                position: relative;
            }

            .tool-tip-data{
                display: none;
                position: absolute;
                background-color: rgb(66, 66, 66);
                color: white;
                right: 12%;
                padding: 0.3rem 0.4rem;
                border-radius: 0.3rem;
                z-index: 9999;
                width: max-content;
            }
            .tool-tip-con:hover{
                cursor: text;
                p{ display: block;}
            }
        }
    }

    .data-table-con{
       
        table{
            
            tr{
                // background-color: $tbNewColor;
                background-color: white;
            }

            tr:nth-child(2n){
                // background-color: $tbNewHighlightedColor;
                background-color: whitesmoke;
            }
            
            th{
                padding: 0.6rem 1rem;
            }

            td{
                padding: 0.6rem 1rem;
            }

            td:nth-child(2n+1){
                text-align: left;
            }

            .goals-tr{
                width: 70% !important;
            }
        }
    }
}

.nrl-player-table-com{
    table{
        overflow: hidden;
        table-layout: auto;
        // //some CSS
        @media screen and (min-width: 220px) and (max-width:340px){
            //do Smth
            width: 240%;
        }
        @media screen and (min-width: 341px) and (max-width:767px) {
            //do Smth
            width: 160%;
        }
        @media screen and (min-width: 769px) {
            //do Smth
            width: 100%;
        }

    }
    
    .tb-data{
        background-color: white;
    }
    .tb-data:nth-child(2n){
        // background-color: $tbNewColor;
        background-color: white;
    }
    .tb-data:nth-child(2n){
        // background-color: $tbNewHighlightedColor;
        background-color: whitesmoke;
    }
    
    .tb-hth-data{
        background-color: rgb(224, 224, 224);
    }
    .tb-th-color{
        background-color: $sortedTHColor;
    }
}


.nba-player-table-com{
    table{
        overflow: hidden;
        table-layout: auto;
        // //some CSS
        @media screen and (min-width: 220px) and (max-width:280px){
            //do Smth
            width: 330%;
        }
        @media screen and (min-width: 281px) and (max-width:400px){
            //do Smth
            width: 210%;
        }
        @media screen and (min-width: 401px) and (max-width:767px) {
            //do Smth
            width: 220%;
        }
        @media screen and (min-width: 769px) {
            //do Smth
            width: 100%;
        }

    }
    
    .tb-data{
        background-color: white;
    }
    .tb-data:nth-child(2n){
        // background-color: $tbNewColor;
        background-color: white;
    }
    .tb-data:nth-child(2n){
        // background-color: $tbNewHighlightedColor;
        background-color: whitesmoke;
    }
    .tb-hth-data{
        background-color: rgb(224, 224, 224);
    }
    .tb-th-color{
        background-color: $sortedTHColor;
    }
}

.epl-player-table-com{
    table{
        overflow: hidden;
        table-layout: auto;
        
        // //some CSS
        @media screen and (min-width: 250px) and (max-width:269px){
            //do Smth
            width: 260%;
        }
        @media screen and (min-width: 270px) and (max-width:299px){
            //do Smth
            width: 245%;
        }
        @media screen and (min-width: 300px) and (max-width:349px){
            //do Smth
            width: 220%;
        }
        @media screen and (min-width: 350px) and (max-width:449px){
            //do Smth
            width: 185%;
        }
        @media screen and (min-width: 450px) and (max-width:493px){
            //do Smth
            width: 145%;
        }
        @media screen and (min-width: 494px) and (max-width:550px) {
            //do Smth
            width: 130%;
        }
        @media screen and (min-width: 551px) and (max-width:600px) {
            //do Smth
            width: 120%;
        }
        @media screen and (min-width: 769px) {
            //do Smth
            width: 100%;margin:2% auto;
        }

    }
    
    .tb-data{
        background-color: white;
    }
    .tb-data:nth-child(2n){
        // background-color: $tbNewColor;
        background-color: white;
    }
    .tb-data:nth-child(2n){
        // background-color: $tbNewHighlightedColor;
        background-color: whitesmoke;
    }
    .tb-hth-data{
        background-color: rgb(224, 224, 224);
    }
    .tb-th-color{
        background-color: $sortedTHColor;
    }
}