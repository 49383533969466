$appBarColor:#001E6C;
$msgConColor:#5089C6;
$TBHeaderColor:#035397;
$menuConBgColor:#035397;
$menuConBorderColor:#34628a;
$menuColor:#ff0058;
$sortedTHColor:#c77d2a;


$tbNewHighlightedColor:#16425e;
$tbNewColor:#16425e;

$AppbarNewHeaderColor1:#ff0058;
$AppbarNewHeaderColor2:#d80e55;


.mlb_sport{

    @media screen and (min-width: 250px) and (max-width:1023px) {
        width: 50%;
        margin: 0% auto;
    }

}

.nba-team-ui,.nrl-team-ui{

    @media screen and (min-width: 355px) and (max-width:560px) {
        height: 25vh;
    }

    @media screen and (min-width: 250px) and (max-width:345px) {
        height: 25vh;
    }

}