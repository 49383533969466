@import '../global.scss';

.privacy-container{
    p{
        text-align: left;
        margin: 1rem 0rem;
    }
    strong{
        margin: 0.5rem 0rem;
    }
    a{
        text-decoration: underline;
    }

    ul{
        padding: 1% 3%;
        li{
            list-style-type: disc;
        }
    }
}